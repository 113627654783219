import axios from 'axios';
import { watchmanDetailsRequest } from '../utils/graphQLRequests/watchmanDetails.request';
import { useSharedStore } from '@/stores/shared';
import { $notify } from '@/plugins/prototypes';
import { parse } from 'lossless-json';
import { useUserStore } from '@/stores/user';

const qs = require('qs');

const BASE_URL = process.env.VUE_APP_API_URL;

const urlParams = new URLSearchParams(window.location.search);
const TENANT_ID = urlParams.get('tenantIdentifier') || process.env.VUE_APP_TENANT;

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { indices: false });
};

const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/v1`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    tenantId: TENANT_ID
  }
});

function toggleLoader (status = true) {
  const sharedStore = useSharedStore();
  sharedStore.setLoading(status);
}

axiosInstance.interceptors.request.use((config) => {
  toggleLoader(true);
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
  toggleLoader(false);
  return response;
}, (error) => {
  const isAuth = localStorage.getItem('ls.tokendetails');
  toggleLoader(false);

  if ((error.response.status === 401 && isAuth) || error.response.data.error === 'invalid_token') {
    $notify.error('Session Expired');
    useUserStore().resetSession();
    return location.assign('#/login');
  }

  return Promise.reject(error);
});

export function setAxiosBearerHeader (token) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function getAxiosBearerHeader () {
  return axiosInstance.defaults.headers.common.Authorization;
}

export function clearAxiosBearerHeader () {
  delete axiosInstance.defaults.headers.common.Authorization;
}

export function setAxios2faHeader (token) {
  axiosInstance.defaults.headers.common['2FA-Token'] = token;
}

export function clearAxios2faHeader () {
  delete axiosInstance.defaults.headers.common['2FA-Token'];
}

const loginDataGenerate = ({ username, password }) => {
  const credential = new FormData();
  credential.append('username', username);
  credential.append('password', password);
  credential.append('client_id', 'backoffice');
  credential.append('client_secret', '123');
  credential.append('grant_type', 'password');
  return credential;
};

export default {
  authentication: {
    login: (credential, requestParams = {}) => axiosInstance.post(`${BASE_URL}/oauth/token`, loginDataGenerate(credential), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, ...requestParams }),
    detail: () => axiosInstance.get('/authenticationdetail'),
    confirmTotpOrOtp: (requestData = {}) => axiosInstance.post('/mfa/requesttoken', requestData),
    requestResetPassword: (requestData = {}) => {
      clearAxiosBearerHeader();
      return axiosInstance.post('/resetpassword/token', requestData);
    },
    resetPassword: (requestData = {}) => axiosInstance.post('/resetpassword', requestData),
    resetPasswordTokenGenerate: () => axiosInstance.post('/resetpassword/token/generate'),
    requestOtp: (requestData = {}) => axiosInstance.post('/otp', requestData),
    logout: () => axiosInstance.post(`${BASE_URL}/oauth/invalidate`),
    ssoLogin: (requestParams = {}) => axiosInstance.post(`${BASE_URL}/oauth/token`, {}, { params: requestParams })
  },
  userDetails: {
    get: () => axiosInstance.get('/userdetails')
  },
  amlGroupsResource: {
    getAll: () => axiosInstance.get('/amlgroup')
  },
  amlScreeningResult: {
    getAll: (requestData = {}) => axiosInstance.get('/amlscreeningresult', { params: requestData }),
    get: (transferId) => axiosInstance.get(`/amlscreeningresult/${transferId}`),
    getTransferDetails: (transferId) => axiosInstance.get(`/amlscreeningresult/transfer/${transferId}`),
    changeStatus: (screeningId, command, requestData = {}) => axiosInstance.put(`/amlscreeningresult/${screeningId}?command=${command}`, requestData),
    update: (screeningId, requestParams = {}, requestData = {}) => axiosInstance.put(`/amlscreeningresult/${screeningId}`, requestData, { params: requestParams })
  },
  clients: {
    getAll: (requestParams = { limit: 100 }) => axiosInstance.get('/clients', { params: requestParams }),
    get: (clientId, requestParams = {}) => axiosInstance.get(`/clients/${clientId}`, { params: requestParams }),
    put: (clientId, requestData = {}, requestParams = {}) => axiosInstance.put(`/clients/${clientId}`, requestData, { params: requestParams }),
    images: (clientId, requestParams = {}) => axiosInstance.get(`/clients/${clientId}/images`, { params: requestParams }),
    charges: (clientId, requestParams = {}) => axiosInstance.get(`/clients/${clientId}/charges`, { params: requestParams }),
    accounts: (clientId, requestParams = {}) => axiosInstance.get(`/clients/${clientId}/accounts`, { params: requestParams }),
    createExternalCard: (clientId, requestData = {}) => axiosInstance.post(`/clients/${clientId}/externalCards`, requestData),
    template: (requestParams = {}) => axiosInstance.get('/clients/template', { params: requestParams }),
    save: (clientId, requestParams = {}, requestData = {}) => axiosInstance.post(`/clients/${clientId}`, requestData, { params: requestParams }),
    addressTemplate: () => axiosInstance.get('/client/addresses/template'),
    downloadTemplate: (requestParams = {}, responseType) => axiosInstance.get('/clients/downloadtemplate', { params: requestParams, responseType }),
    upload: (requestData = {}, requestParams = {}) => axiosInstance.post('/clients/uploadtemplate', requestData, { params: requestParams }),
    getAllNOC: (clientId, requestData = {}) => axiosInstance.get(`/clients/${clientId}/noc`, { params: requestData }),
    getAssociatedClients: (clientId, requestParams = {}) => axiosInstance.get(`/clients/${clientId}/linkedclients`, { params: requestParams }),
    linkedClient: (clientId, requestData = {}) => axiosInstance.post(`/clients/${clientId}/linkedclients`, requestData),
    initiateKyc: (associatedClientId, command, requestData = {}) => axiosInstance.post(`/clients/${associatedClientId}?command=${command}`, requestData),
    getPrimaryAccounts: (clientId, requestParams = {}) => axiosInstance.get(`/clients/${clientId}/primaryaccounts`, { params: requestParams }),
    kycRequestStatusTemplate: () => axiosInstance.get('/clients/kyc-request/template'),
    getGeneratedDocUploadToken: (clientId) => axiosInstance.get(`clients/${clientId}/token/documents`),
    generateDocUploadToken: (clientId) => axiosInstance.post(`clients/${clientId}/token/documents`),
    getPaymentRails: (clientId) => axiosInstance.get(`/clients/${clientId}/transferoptions`),
    updatePaymentRails: (clientId, requestData = {}) => axiosInstance.put(`/clients/${clientId}/transferoptions`, requestData),
    verify: (clientId, requestData = {}) => axiosInstance.post(`/clients/${clientId}?command=verify`, requestData),
    externalKycRequestLogs: (clientId) => axiosInstance.get(`/clients/kyc-request/${clientId}/log`)
  },
  suspiciousclients: {
    get: (requestParams = {}) => axiosInstance.get(`/suspiciousclients`, { params: requestParams }),
    save: (suspiciousclientId, requestData = {}, requestParams = {}) => axiosInstance.post(`/suspiciousclients/${suspiciousclientId}`, requestData, { params: requestParams })
  },
  clientOfacNote: {
    create: (clientId, requestData = {}) => axiosInstance.post(`/clientOfac/${clientId}/notes`, requestData)
  },
  creditCardDispute: {
    getDisputeReasons: (requestParams = {}) => axiosInstance.get('/dispute/reason', { params: requestParams }),
    getDisputeFieldsTemplate: (disputeReasonTypeId) => axiosInstance.get(`/dispute/configuration/${disputeReasonTypeId}`),
    createDispute: (requestData = {}, requestParams = {}) => axiosInstance.post('/dispute', requestData, { params: requestParams }),
    uploadFile: (requestData = {}) => axiosInstance.post(`/dispute/documents`, requestData,
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }),
    getAllDisputes: (requestParams = {}) => axiosInstance.get('/dispute', { params: requestParams }),
    getDispute: (disputeId, requestParams = {}) => axiosInstance.get(`/dispute/${disputeId}`, { params: requestParams }),
    getDisputeNote: (disputeId) => axiosInstance.get(`/dispute/${disputeId}/notes`),
    addDisputeNote: (disputeId, requestData = {}) => axiosInstance.post(`/dispute/${disputeId}/notes`, requestData),
    adjustAmount: (disputeId, txId, requestParams = {}, requestData = {}) => axiosInstance.put(`/dispute/${disputeId}/transaction/${txId}`, requestData, { params: requestParams }),
    closeDispute: (disputeId, requestParams = {}, requestData = {}) => axiosInstance.put(`/dispute/${disputeId}`, requestData, { params: requestParams }),
    getDisputeAttachments: (disputeId) => axiosInstance.get(`/dispute/${disputeId}/documents`),
    getDisputeAttachment: (docId) => axiosInstance.get(`/dispute/documents/${docId}/attachment`, { responseType: 'blob' }),
    getcreditTxTemplate: () => axiosInstance.get('/dispute/credit/transaction/template')
  },
  audits: {
    get: (requestParams = {}) => axiosInstance.get('/audits', { params: requestParams, transformResponse: (data) => parse(data) })
  },
  imports: {
    downloadDocument: (requestParams = {}, responseType) => axiosInstance.get('/imports/downloadOutputTemplate', { params: requestParams, responseType }),
    get: (requestParams = {}) => axiosInstance.get('imports', { params: requestParams })
  },
  clientAddress: {
    save: (clientId, requestParams = {}, requestData = {}) => axiosInstance.post(`client/${clientId}/addresses`, requestData, { params: requestParams }),
    put: (clientId, requestParams = {}, requestData = {}) => axiosInstance.put(`client/${clientId}/addresses`, requestData, { params: requestParams }),
    get: (clientId, requestParams = {}) => axiosInstance.get(`client/${clientId}/addresses`, { params: requestParams }),
    delete: (clientId, id, requestParams = {}) => axiosInstance.delete(`client/${clientId}/addresses/${id}`, { params: requestParams })
  },
  clientPhoneNumber: {
    get: (clientId, id, requestParams = {}) => axiosInstance.get(`clients/${clientId}/mobile/${id}`),
    save: (clientId, requestData = {}, requestParams = {}) => axiosInstance.post(`clients/${clientId}/mobile`, requestData, { params: requestParams }),
    delete: (clientId, id) => axiosInstance.delete(`clients/${clientId}/mobile/${id}`),
    put: (clientId, id, requestData = {}, requestParams = {}) => axiosInstance.put(`clients/${clientId}/mobile/${id}`, requestData, { params: requestParams })
  },
  codeResources: {
    getAllCodes: () => axiosInstance.get('/codes'),
    getCodes: (codeId) => axiosInstance.get(`/codes/${codeId}`),
    save: (requestData = {}) => axiosInstance.post(`/codes`, requestData),
    update: (codeId, requestData = {}) => axiosInstance.put(`/codes/${codeId}`, requestData),
    delete: (codeId) => axiosInstance.delete(`/codes/${codeId}`)
  },
  cardsResource: {
    save: (requestData = {}) => axiosInstance.post(`/cards`, requestData),
    update: (cardId, requestData = {}, requestParams = {}) => axiosInstance.put(`/cards/${cardId}`, requestData, { params: requestParams }),
    get: (cardId, requestParams = {}) => axiosInstance.get(`/cards/${cardId}`, { params: requestParams }),
    command: (cardId, command, requestData = {}) => axiosInstance.post(`/cards/${cardId}?command=${command}`, requestData)
  },
  cardAuthorizationsByCardResource: {
    get: (token, requestParams = {}) => axiosInstance.get(`/cards/${token}/authorization`, { params: requestParams })
  },
  clientResource: {
    get: (clientId, requestParams = {}) => axiosInstance.get(`/clients/${clientId}`, { params: requestParams }),
    update: (clientId, requestData = {}) => axiosInstance.put(`/clients/${clientId}`, requestData),
    getSavingAccounts: (clientId, anotherresource, requestParams = {}) => axiosInstance.get(`/clients/${clientId}/${anotherresource}`, requestParams),
    getAuthorizations: (requestParams = {}) => axiosInstance.get(`/clientauthorizations`, { params: requestParams }),
    getIdentifierDocument: (identifierId, documentId, responseType = 'blob') => axiosInstance.get(`client_identifiers/${identifierId}/documents/${documentId}/attachment`, { responseType })
  },
  cardProductsResource: {
    get: (cardProductId, requestParams = {}) => axiosInstance.get(`/cardproducts/${cardProductId}`, { params: requestParams }),
    getCard: () => axiosInstance.get(`/cardproducts`),
    update: (cardProductId) => axiosInstance.put(`/cardproducts/${cardProductId}`),
    updateStatus: (cardProductId, command, requestData = {}) => axiosInstance.post(`/cardproducts/${cardProductId}?command=${command}`, requestData)
  },
  clientClassification: {
    getAll: () => axiosInstance.get(`/classifications`),
    get: (clientClassificationId) => axiosInstance.get(`/classifications/${clientClassificationId}/velocityrules`),
    save: (clientClassificationId, requestData = {}) => axiosInstance.post(`/classifications/${clientClassificationId}/velocityrules`, requestData),
    update: (clientClassificationId, requestData = {}) => axiosInstance.put(`/classifications/${clientClassificationId}/velocityrules`, requestData),
    template: () => axiosInstance.get(`/classifications/template`),
    removeCard: (clientClassificationId, cardProductId) => axiosInstance.delete(`/classifications/${clientClassificationId}/cardproducts/${cardProductId}`)
  },
  savingAccountsProducts: {
    getAll: () => axiosInstance.get(`/savingsproducts`)
  },
  savingChargeClassification: {
    getAll: () => axiosInstance.get('/savingschargeclassificationmappings'),
    template: () => axiosInstance.get('/savingschargeclassificationmappings/template'),
    get: (savingChargeClassificationId) => axiosInstance.get(`/savingschargeclassificationmappings/${savingChargeClassificationId}`),
    delete: (savingChargeClassificationId) => axiosInstance.delete(`/savingschargeclassificationmappings/${savingChargeClassificationId}`),
    create: (requestData = {}) => axiosInstance.post(`/savingschargeclassificationmappings`, requestData),
    edit: (requestData = {}, savingChargeClassificationId) => axiosInstance.put(`/savingschargeclassificationmappings/${savingChargeClassificationId}`, requestData)
  },
  codeValueResource: {
    getAllCodeValues: (codeId) => axiosInstance.get(`/codes/${codeId}/codevalues`),
    getCodeValueByName: (codeValueName) => axiosInstance.get(`/codeValues?codeName=${codeValueName}`),
    getCodeValue: (codeId, codeValueId) => axiosInstance.get(`/codes/${codeId}/codevalues/${codeValueId}`),
    update: (codeId, codeValueId, requestData = {}) => axiosInstance.put(`/codes/${codeId}/codevalues/${codeValueId}`, requestData),
    delete: (codeId, codeValueId) => axiosInstance.delete(`/codes/${codeId}/codevalues/${codeValueId}`),
    addCodeValue: (codeId, requestData = {}) => axiosInstance.post(`/codes/${codeId}/codevalues`, requestData)
  },
  clientNote: {
    get: (clientId) => axiosInstance.get(`/clients/${clientId}/notes`),
    post: (clientId, requestData = {}) => axiosInstance.post(`/clients/${clientId}/notes`, requestData)
  },
  userResource: {
    get: (userId) => axiosInstance.get(`/users/${userId}`),
    getAll: (requestParams = {}) => axiosInstance.get('/users', { params: requestParams }),
    template: (requestParams = {}) => axiosInstance.get(`/users/template`, { params: requestParams }),
    create: (requestData = {}) => axiosInstance.post(`/users`, requestData),
    update: (userId, requestData = {}) => axiosInstance.put(`/users/${userId}`, requestData),
    delete: (userId) => axiosInstance.delete(`/users/${userId}`),
    getDocuments: (userId) => axiosInstance.get(`/users/${userId}/documents`),
    deleteDocument: (userId, documentId) => axiosInstance.get(`/users/${userId}/documents/${documentId}`),
    getAttachment: (userId, documentId, responseType = 'blob') => axiosInstance.get(`/users/${userId}/documents/${documentId}/attachment`, { responseType }),
    resetPassword: (userId) => axiosInstance.put(`/users/${userId}/resetpassword`)
  },
  staffResource: {
    getAll: (requestParams = {}) => axiosInstance.get(`/staff`, { params: requestParams })
  },
  creditAccountResource: {
    get: (accountId, requestParams = {}) => axiosInstance.get(`/creditcards/${accountId}`, { params: requestParams }),
    save: (creditAccountId, requestData = {}, requestParams = {}) => axiosInstance.post(`/creditcards/${creditAccountId}`, requestData, { params: requestParams }),
    update: (accountId, requestData = {}) => axiosInstance.put(`/creditcards/${accountId}`, requestData),
    template: (requestParams = {}) => axiosInstance.get(`creditcards/template`, { params: requestParams }),
    closureTemplate: (creditAccountId, requestParams = {}) => axiosInstance.get(`/creditcards/${creditAccountId}/closureTemplate`, { params: requestParams }),
    getTransaction: (accountId, txId) => axiosInstance.get(`/creditcards/${accountId}/transactions/${txId}`),
    getTransactionDetails: (creditAccountId, transactionId, requestParams = {}) => axiosInstance.get(`/creditcards/${creditAccountId}/transactions/${transactionId}`, { params: requestParams }),
    actions: (creditAccountId, transactionId, requestData = {}, requestParams = {}) => axiosInstance.post(`/creditcards/${creditAccountId}/transactions/${transactionId}`, requestData, { params: requestParams })
  },
  documents: {
    getAll: (entityType, entityId, requestParams = {}) => axiosInstance.get(`/${entityType}/${entityId}/documents`, { params: requestParams }),
    getRegistrationAttachmentDetail: (clientId, registeredDataTable, documentId) => axiosInstance.get(`/${registeredDataTable}/${clientId}/documents/${documentId}`),
    downloadRegistrationAttachment: (clientId, registeredDataTable, documentId, responseType) => axiosInstance.get(`/${registeredDataTable}/${clientId}/documents/${documentId}/attachment`, { responseType })
  },
  externalCard: {
    getKeyId: () => axiosInstance.get('/externalCards/key')
  },
  officeResource: {
    getAllOffices: () => axiosInstance.get('/offices'),
    getOffices: (officeId, requestParams = {}) => axiosInstance.get(`/offices/${officeId}`, { params: requestParams }),
    getTemplate: (requestParams = {}) => axiosInstance.get(`/offices/template`, { params: requestParams })
  },
  prepaidCard: {
    create: (primaryAccountId, requestData = {}) => axiosInstance.post(`/savingsaccounts/${primaryAccountId}/prepaidcards`, requestData),
    getAll: (primaryAccountId, requestParams = {}) => axiosInstance.get(`/savingsaccounts/${primaryAccountId}/prepaidcards`, { params: requestParams }),
    getPrimaryAccounts: (clientId, requestParams = {}) => axiosInstance.get(`/clients/${clientId}/primaryaccounts`, { params: requestParams }),
    linkClient: (clientId, requestParams = {}) => axiosInstance.get(`/clients/${clientId}/linkedclients`, { params: requestParams }),
    deactivate: (primaryAccountId, prepaidCardId) => axiosInstance.post(`/savingsaccounts/${primaryAccountId}/prepaidcards/${prepaidCardId}`)
  },
  notifications: {
    getAllNotifications: (requestParams = {}) => axiosInstance.get('/notifications', { params: requestParams }),
    readNotifications: () => axiosInstance.put('/notifications'),
    retryFailsNotification: (requestData) => axiosInstance.post('/notifications/retry', requestData)
  },
  organization: {
    get: () => axiosInstance.get('/organization'),
    update: (requestData = {}) => axiosInstance.put('/organization', requestData)
  },
  organizationTimezone: {
    get: () => axiosInstance.get('/organization/timezone')
  },
  transfer: {
    getTransfers: (requestParams = {}) => axiosInstance.get('/transfers', { params: requestParams }),
    getTransfersErrorCodes: () => axiosInstance.get('/transfers/template'),
    getAllAchProcessingPending: (requestParams = {}) => axiosInstance.get('/transfer/ach/processing', { params: requestParams }),
    triggerAchProcessing: (requestData = {}) => axiosInstance.post('/transfer/ach/processing', requestData),
    getAllAchProcessingHistory: (requestParams = {}) => axiosInstance.get('/transfer/ach/processing/history', { params: requestParams }),
    saveTransfer: (transferId, command, requestData = {}) => axiosInstance.post(`/transfers/${transferId}?command=${command}`, requestData),
    updateTransfer: (transferId, requestData = {}) => axiosInstance.put(`/transfers/${transferId}`, requestData),
    returnTransfers: (requestData = {}) => axiosInstance.post(`/transfers/return`, requestData),
    triggerAchOutbound: (requestData = {}, requestParams = {}) => axiosInstance.post('/transfer/ach/outboundfiles', requestData, { params: requestParams }),
    triggerAchReturn: (requestData = {}, requestParams = {}) => axiosInstance.post('/transfer/ach/returnfiles', requestData, { params: requestParams }),
    getAllPendingNOC: (requestParams = {}) => axiosInstance.get('/transfers/noc', { params: requestParams }),
    cancelTransfer: (transferId, requestData = {}, requestParams = {}) => axiosInstance.post(`/transfers/${transferId}`, requestData, { params: requestParams })
  },
  accounting: {
    getAllAccountsCOAS: (requestParams = {}) => axiosInstance.get('/glaccounts', { params: requestParams }),
    getJournalEntries: (requestParams = {}) => axiosInstance.get('/journalentries', { params: requestParams }),
    downloadJournalEntries: (requestParams = {}) => axiosInstance.get('/journalentries/exportexcel', { params: requestParams, responseType: 'arraybuffer' })
  },
  watchmanLists: {
    getWatchmanDetails: (id) => id ? axiosInstance.post(`${BASE_URL}/graphql`, { query: watchmanDetailsRequest(id) }) : [],
    registrationSummary: (clientId) => axiosInstance.get(`/clients/${clientId}/onBoarding`)
  },
  template: {
    get: () => axiosInstance.get('/templates'),
    save: (requestData) => axiosInstance.post('/templates', requestData),
    resource: () => axiosInstance.get('/templates/template'),
    edit: (templateId, requestData = {}) => axiosInstance.put(`/templates/${templateId}`, requestData),
    view: (templateId) => axiosInstance.get(`/templates/${templateId}`),
    delete: (templateId) => axiosInstance.delete(`/templates/${templateId}`)
  },
  stopPayments: {
    template: (savingsAccountId) => axiosInstance.get(`/savingsaccounts/${savingsAccountId}/stoppayments/template`),
    create: (savingsAccountId, requestData = {}) => axiosInstance.post(`/savingsaccounts/${savingsAccountId}/stoppayments`, requestData),
    charges: (chargeTimeType, requestParams = {}) => axiosInstance.get('/charges', { params: requestParams })
  },
  getAuthTransaction: {
    get: (savingId, externalAuthId) => axiosInstance.get(`/savingsaccount/${savingId}/authorizations?externalAuthId=${externalAuthId}`)
  },
  manageEmployeeAccounts: {
    get: (primaryAccountId, requestParams = {}) => axiosInstance.get(`/savingsaccounts/${primaryAccountId}/subsidiaryaccounts`, { params: requestParams }),
    post: (entityId, requestData = {}) => axiosInstance.post(`/clients/${entityId}/linkedclients`, requestData),
    activateOrDeactivate: (primaryAccountId, subSavingAccountId, command, requestData = {}) => axiosInstance.post(`/savingsaccounts/${primaryAccountId}/subsidiaryaccounts/${subSavingAccountId}?command=${command}`, requestData),
    template: (entityId) => axiosInstance.get(`/clients/${entityId}/linkedclients/template`),
    fundAllocate: (primaryAccountId, requestData = {}) => axiosInstance.post(`/savingsaccounts/${primaryAccountId}/subsidiaryaccounts/funding`, requestData),
    fundDeallocate: (primaryAccountId, requestData = {}) => axiosInstance.post(`/savingsaccounts/${primaryAccountId}/subsidiaryaccounts/deallocate`, requestData),
    createSubAccount: (entityId, clientId, requestData = {}) => axiosInstance.post(`/clients/${entityId}/linkedclients/${clientId}/subsidiaryaccounts`, requestData)
  },
  rolesAndPermissions: {
    create: (requestData) => axiosInstance.post('/roles', requestData),
    getAllRoles: (requestParams = {}) => axiosInstance.get(`roles`, { params: requestParams }),
    getRolePermissions: (roleId) => axiosInstance.get(`roles/${roleId}/permissions`),
    updateRoleDetails: (roleId, requestData = {}) => axiosInstance.put(`/roles/${roleId}`, requestData),
    updateRolePermissions: (roleId, requestData = {}) => axiosInstance.put(`/roles/${roleId}/permissions`, requestData),
    action: (roleId, requestParams = {}) => axiosInstance.post(`/roles/${roleId}`, {}, { params: requestParams }),
    delete: (roleId) => axiosInstance.delete(`roles/${roleId}`)
  },
  swiftManualService: {
    get: () => axiosInstance.get('/swiftmanual'),
    put: (requestData = {}) => axiosInstance.put(`/swiftmanual`, requestData)
  },
  newAmlScreeningResultDetailsResource: {
    get: (id, requestParams = {}) => axiosInstance.get(`/amlscreeningresultdetails/${id}`, { params: requestParams })
  },
  savingAccounts: {
    get: (savingAccountId, requestParams = {}) => axiosInstance.get(`/savingsaccounts/${savingAccountId}`, { params: requestParams }),
    createSubledger: (savingAccountId, requestData = {}, requestParams = {}) => axiosInstance.post(`/savingsaccounts/${savingAccountId}/subledger`, requestData, { params: requestParams }),
    transactionAction: (savingAccountId, requestData = {}, requestParams = {}) => axiosInstance.post(`/savingsaccounts/${savingAccountId}/transactions`, requestData, { params: requestParams }),
    post: (savingAccountId, requestData = {}, requestParams = {}) => axiosInstance.post(`/savingsaccounts/${savingAccountId}`, requestData, { params: requestParams }),
    put: (savingAccountId, requestData = {}, requestParams = {}) => axiosInstance.put(`/savingsaccounts/${savingAccountId}`, requestData, { params: requestParams }),
    transactionsTemplate: (accountId, requestParams = {}) => axiosInstance.get(`/savingsaccounts/${accountId}/transactions/searchtemplate`, { params: requestParams }),
    getAverageDailyBalance: (savingAccountId, requestParams = {}) => axiosInstance.get(`/savingsaccounts/${savingAccountId}/averageDailyBalance`, { params: requestParams })
  },
  savingsChargeResource: {
    post: (savingAccountId, requestData = {}) => axiosInstance.post(`/savingsaccounts/${savingAccountId}/charges`, requestData),
    template: (savingAccountId) => axiosInstance.get(`/savingsaccounts/${savingAccountId}/charges/template`)
  },
  paymentTypeResource: {
    getAll: (requestParams = {}) => axiosInstance.get(`/paymenttypes`, { params: requestParams })
  },
  chargeResource: {
    get: (chargeId, requestParams = {}) => axiosInstance.get(`/charges/${chargeId}`, { params: requestParams })
  },
  tier: {
    add: (clientId, requestData = {}) => axiosInstance.post(`/clients/${clientId}/tiers`, requestData),
    get: (clientId, requestData = {}) => axiosInstance.get(`/clients/${clientId}/tiers`, requestData),
    update: (clientId, tierId, requestData = {}) => axiosInstance.put(`/clients/${clientId}/tiers/${tierId}`, requestData),
    addTierVelocity: (clientId, tierId, requestData = {}) => axiosInstance.post(`/clients/${clientId}/tiers/${tierId}/velocityrules`, requestData),
    getTierVelocity: (clientId, tierId) => axiosInstance.get(`/clients/${clientId}/tiers/${tierId}/velocityrules`),
    updateTierVelocity: (clientId, tierId, requestData = {}) => axiosInstance.put(`/clients/${clientId}/tiers/${tierId}/velocityrules`, requestData),
    deleteTierVelocity: (clientId, tierId, requestData = {}) => axiosInstance.delete(`/clients/${clientId}/tiers/${tierId}/velocityrules`, requestData)
  },
  agingBucketResource: {
    get: () => axiosInstance.get('/agingbuckets'),
    post: (requestData = {}) => axiosInstance.post('/agingbuckets', requestData),
    put: (agingbucketId, requestData = {}) => axiosInstance.put(`/agingbuckets/${agingbucketId}`, requestData),
    delete: (agingbucketId) => axiosInstance.delete(`/agingbuckets/${agingbucketId}`)
  },
  paymentRails: {
    getInstanceLevel: (requestParams = {}) => axiosInstance.get(`/instance`, { params: requestParams }),
    updateInstanceLevel: (requestData = {}) => axiosInstance.put(`/instance`, requestData)
  },
  riskRating: {
    get: (clientId) => axiosInstance.get(`/clients/${clientId}/risk-rating`),
    template: () => axiosInstance.get('/clients/risk-ratings/template'),
    update: (clientId, requestData = {}) => axiosInstance.put(`/clients/${clientId}/risk-rating`, requestData)
  },
  report: {
    generate: (resource, requestParams = {}) => {
      const resourceName = encodeURIComponent(resource);

      return axiosInstance.get(`/runreports/${resourceName}`, {
        params: requestParams,
        responseType: 'arraybuffer'
      });
    }
  },
  creditProducts: {
    getAll: () => axiosInstance.get(`/creditproducts`)
  },
  loanProducts: {
    getAll: (requestParams = {}) => axiosInstance.get('/loanproducts', { params: requestParams })
  },
  identifiers: {
    template: (clientId) => axiosInstance.get(`/clients/${clientId}/identifiers/template`),
    add: (clientId, requestData = {}) => axiosInstance.post(`/clients/${clientId}/identifiers`, requestData),
    update: (clientId, identifierId, requestData = {}) => axiosInstance.put(`/clients/${clientId}/identifiers/${identifierId}`, requestData),
    get: (clientId, identifierId, requestParams = {}) => axiosInstance.get(`/clients/${clientId}/identifiers/${identifierId}`, { params: requestParams })
  },
  graphql: {
    get: (requestQuery = {}) => axiosInstance.post(`${BASE_URL}/graphql`, { query: requestQuery })
  },
  configurations: {
    getByName: (configName) => axiosInstance.get(`/configurations/name/${configName}`)
  },
  fieldConfiguration: {
    getByName: (entity) => axiosInstance.get('/fieldconfigurations', { params: { entity } })
  }
};
