import { render, staticRenderFns } from "./TxPendingReturnACH.vue?vue&type=template&id=a08b1200"
import script from "./TxPendingReturnACH.vue?vue&type=script&lang=js"
export * from "./TxPendingReturnACH.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports